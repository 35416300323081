import { get, post, put } from 'utils/api';
import {
  GET_GOALS,
  PERSONALISATION,
  CAMPAIGN,
  GET_CAMPAIGN,
  CAMPAIGN_SUMMARY,
  CAMPAIGN_STATUS,
} from 'utils/URLs';

export const getGoals = () => get(GET_GOALS);
export const getPersonalisation = (id) => get(`${PERSONALISATION}/${id}`);
export const createCampaign = (data) => post(CAMPAIGN, data);
export const updateCampaign = (id, data) => put(`${CAMPAIGN}/${id}`, data);
export const getCampaignlist = (id, data) => get(`${GET_CAMPAIGN}/${id}`, data);
export const getCampaign = (id, data) => get(`${CAMPAIGN}/${id}`, data);
export const getCampaignSummary = (issuerId, data) =>
  get(`${CAMPAIGN_SUMMARY}/${issuerId}`, data);
export const updateCampaignStatus = (id, data) =>
  put(`${CAMPAIGN_STATUS}/${id}`, data);
