import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  mb: 5,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(18px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#38E93F',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: '#C49200',
    boxSizing: 'border-box',
  },
}));

const StatusSwitch = ({ status, updateStatus }) => {
  return (
    <AntSwitch
      checked={status}
      defaultChecked={true}
      inputProps={{ 'aria-label': 'ant design' }}
      onChange={updateStatus}
    />
  );
};

StatusSwitch.propTypes = {
  status: PropTypes.bool.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

export default StatusSwitch;
