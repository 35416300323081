export default {
    h1: {
        fontsize: '22px',
    },
    h2: {
        fontsize: '20px',
    },
    h3: {
        fontsize: '18px',
    },
    p1:
    {
        fontsize: '16px',
    },
    p2:
    {
        fontsize: '14px',
    },
    p3:
    {
        fontsize: '12px',
    },
    
  };