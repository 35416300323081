export const defaultColors = {
  primary: '#36C2D9',
  secondary: '#3C9DB4',
  backgroudColor: '#EDECEC',
  buttonColor: '#45D5E2',
};

const otherColors = {
  gray: '#E0E0E0',
  darkGray: '#575757',
  otherGray: '#616161',
  white: '#FFFFFF',
  offWhite: '#F5F5F5',
  black: '#373737',
  darkLyfe: '#419CB1',
  darkLyfe2: '#0E7E90',
  lightLyfe: '#81DBEA',
  realLyfe: '#45D5E2',
  midGray: '#F8F8F8',
  transparent: 'transparent',
  midDarkGray: '#E3E3E3',
  lightGray: '#FAFAFA',
  lightGray2: '#F2F2F2',
  teal: '#E5E5E5',
  gray2: '#D6D6D6',
};
const gradients = {
  darkBlue: 'linear-gradient(0deg, #419CB1, #419CB1), #D9D9D9',
};
export default {
  defaultColors,
  ...otherColors,
  gradients,
};
