import styled from 'styled-components';

export const OverallBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  width: 22%;
  border-radius: 12px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  padding-left: 20px;
`;

export const TitleFormat = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 9%;
`;

export const ValueFormat = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black};
  margin: 4% 0;
`;

export const SeeMoreButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.midGray};
  border: 1px solid ${({ theme }) => theme.colors.black};
  width: 159px;
  height: 29px;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 7%;
  :hover {
    background-color: ${({ theme }) => theme.colors.midDarkGray};
    cursor: pointer;
  }
`;
