import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: '',
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorInfo: error.message || '',
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>
            Something went wrong!
            <br />
            {this.state.errorInfo}
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
