import React, { useState } from 'react';
import moment from 'moment';
import PopUpBox from 'containers/PopUpBox';
import CreateCampaign from 'containers/CreateCampaign';
import { getStorageData } from 'utils/helper';
import CampaignConfirmation from 'containers/CampaignConfirmation';
import { PopUpCanvas } from './styles';

const defaultCampaign = {
  name: '',
  goal_id: '',
  rule_id: '',
  increase_by_customers: '',
  customers_engaged: 1,
  location: 0,
  spent_amount: '',
  days_active: [],
  number_of_times: '',
  start_time: '',
  end_time: '',
  venues: [],
};
const PopUp = () => {
  const { id, key } = getStorageData('user');
  const [campaign, setCampaign] = useState({
    step: 0,
    data: {
      user_id: id,
      issuer_id: key,
      start_date: null,
      end_date: null,
      ...defaultCampaign,
    },
  });

  const screenUpdate = ({ step, data }) => {
    setCampaign({ step, data: { ...campaign.data, ...data } });
  };

  const getCampaignPopup = () => {
    if (campaign.step == 0) {
      return <PopUpBox changePopUp={screenUpdate} campaign={campaign} />;
    }
    if (campaign.step == 1) {
      return (
        <CreateCampaign changePopUp={screenUpdate} campaignData={campaign} />
      );
    }
    if (campaign.step == 2) {
      return (
        <CampaignConfirmation changePopUp={screenUpdate} campaign={campaign} />
      );
    }
  };

  return <PopUpCanvas>{getCampaignPopup()}</PopUpCanvas>;
};

export default PopUp;
