import styled from 'styled-components';

export const ButtonOrg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 220px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.colors.defaultColors.primary};
    color: ${({ theme }) => theme.colors.white};
    font-size: 60px;
  }
  &.active {
    background: ${({ theme }) => theme.colors.defaultColors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ImageFormat = styled.div`
  width: 75px;
  height: 75px;
`;

export const GoalText = styled.div`
  margin-top: 12px;
  line-height: 20px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  width: 90%;
  text-align: center;
`;

export const DescriptionText = styled.div`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.darkGray};
`;
