export const checkRequried = (data, field = {}) => {
  let faild = false;
  Object.keys(field).forEach((input) => {
    if (!data[input] && field[input] === 'required') faild = true;
  });
  return faild;
};

export const checkRequiredField = (key, value) => {
  const message = value !== 0 && !value ? 'This field is required' : '';
  return { [key]: message };
};

export const checkAllRequiredFields = (fields) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    const message = !value ? 'This field is required' : '';
    return { ...acc, [key]: message };
  }, {});

export const handleServerErrors = (ServerErrors) =>
  Object.entries(ServerErrors).reduce(
    (acc, [key, [value]]) => ({ ...acc, [key]: value }),
    {},
  );

export const validateNumber = (value, key = null) => {
  switch (true) {
    case !value:
      return key != null
        ? { [key]: 'This field is required' }
        : 'This field is required';

    case value < 0:
      return { [key]: 'No negative' };

    case isNaN(value):
      return key != null
        ? { [key]: 'Only number value is allow' }
        : 'Only number value is allow';

    default:
      return key != null ? { [key]: '' } : '';
  }
};

export const maxMinNumber = (key, value, min = 1, max = 20) => {
  switch (true) {
    case isNaN(value):
      return { [key]: 'Only number value is allow' };
    case !value || value === 0:
      return { [key]: 'This field is required' };
    case min > value:
      return { [key]: `minimum value should be ${min}` };
    case value > max:
      return { [key]: `maximum value should be ${max}` };
    default:
      return { [key]: '' };
  }
};

export const positiveNumber = (key, value) => {
  switch (true) {
    case value < 0:
      return { [key]: 'Negative values are not allowed' };
    default:
      return { [key]: '' };
  }
};

export const checkAllRequiredFieldsWithKey = (fields, values) =>
  Object.entries(fields).reduce((acc, [key]) => {
    const message =
      values[key] !== 0 && !values[key] ? 'This field is required' : '';
    return { ...acc, [key]: message };
  }, {});

export const isEmptyArray = (
  key,
  value,
  errorMessage = 'Please select aleast one',
) => {
  const message = !value.length ? errorMessage : '';
  return { [key]: message };
};
