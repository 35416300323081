import styled from 'styled-components';

export const OverallBox = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, white 15%, #f2f2f2 0.1%, #f2f2f2 85%);
  width: 950px;
  min-height: 600px;
  border-radius: 14px;
  align-items: center;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
`;

export const PopUpHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3%;
  width: 90%;
`;

export const PopUpHeaderText = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 22px;
  color: ${({ theme }) => theme.colors.black};
`;

export const PopUpButtonsOrg = styled.div`
  display: flex;
  margin-top: 6%;
  width: 590px;
  height: 475px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
