import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { Formik, Form } from 'formik';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { resetPassword, validateToken } from 'apiServices/auth';
import { handleApiErrors } from 'utils/helper.js';
import {
  LoginContainer,
  CenterBox,
  StyledInputBox,
  ButtonBox,
  StyledHeading,
} from './styles.js';

const initialData = {
  email: '',
  password: '',
  confirm_password: '',
};
const ResetPassword = () => {
  const [user, setUser] = useState({});
  const [apiError, setApiError] = useState('');
  const [success, setSuccess] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    validateToken(token)
      .then(({ data }) => {
        setUser(data);
      })
      .catch(({ errors }) => {
        setApiError(errors.errors.token);
      });
  }, []);

  const delayRedirect = () => {
    setTimeout(() => navigate('/'), 1500);
  };

  const handleSubmit = async (values, { setErrors }) => {
    const formData = { ...values, email: user.email, token };
    resetPassword(formData)
      .then(({ message }) => {
        setSuccess(message);
        delayRedirect();
      })
      .catch(({ errors }) => {
        setErrors(handleApiErrors(errors));
      });
  };
  return (
    <Formik
      initialValues={initialData}
      validationSchema={Yup.object({
        password: Yup.string()
          .required('This field is required.')
          .min(6, 'Password must be at least 6 characters long'),
        confirm_password: Yup.string()
          .required('This field is required.')
          .min(6, 'Password must be at least 6 characters long'),
      })}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <LoginContainer>
            <CenterBox>
              <StyledHeading>Reset your Password</StyledHeading>
              {success && <Alert severity="success">{success}</Alert>}
              {apiError && <Alert severity="error">{apiError}</Alert>}
              <StyledInputBox>
                <InputField
                  id="password"
                  name="password"
                  type="password"
                  placeholder="••••••••"
                  label="Password"
                />
              </StyledInputBox>
              <StyledInputBox>
                <InputField
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  placeholder="••••••••"
                  label="Confirm password"
                />
              </StyledInputBox>
              <ButtonBox>
                <Button
                  data-testid="submit"
                  buttonType="primary"
                  variant="contained"
                  type="submit"
                  width="200px"
                  classes={{ root: 'reset-password' }}
                >
                  Change Password
                </Button>
                <Link to="/">Back to sign in</Link>
              </ButtonBox>
            </CenterBox>
          </LoginContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
