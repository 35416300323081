import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PopUpBox from 'containers/PopUpBox';
import CreateCampaign from 'containers/CreateCampaign';
import CampaignConfirmation from 'containers/CampaignConfirmation';
import { getCampaign } from 'apiServices/issuer';
import { PopUpCanvas } from './styles';

const CampaignEdit = () => {
  const [campaign, setCampaign] = useState({
    step: 0,
    data: {},
  });
  const params = useParams();
  useEffect(() => {
    getCampaign(params.id)
      .then(({ data }) => {
        setCampaign({
          step: 0,
          data: { ...campaign.data, ...data },
        });
      })
      .catch(() => {});
  }, []);

  const screenUpdate = ({ step, data }) => {
    setCampaign({
      step,
      data: {
        ...campaign.data,
        ...data,
        days_active: JSON.parse(data.days_active),
      },
    });
  };

  const getCampaignPopup = () => {
    if (campaign.step == 0) {
      return <PopUpBox changePopUp={screenUpdate} campaign={campaign} />;
    }
    if (campaign.step == 1) {
      return (
        <CreateCampaign
          changePopUp={screenUpdate}
          campaignData={campaign}
          isEdit
        />
      );
    }
    if (campaign.step == 2) {
      return (
        <CampaignConfirmation
          changePopUp={screenUpdate}
          campaign={campaign}
          isEdit
        />
      );
    }
  };

  return <PopUpCanvas>{getCampaignPopup()}</PopUpCanvas>;
};

export default CampaignEdit;
