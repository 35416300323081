import React from 'react';
import PropTypes from 'prop-types';
import { StyledDiv, StyledError } from './styles.js';
import { TextField } from '@mui/material';

const CustomInput = ({ label, error, inputBoxClass, ...props }) => {
  return (
    <StyledDiv>
      <label htmlFor={props.name}>{label}</label>
      <div className={inputBoxClass}>
        <TextField className="text-input" {...props} />
      </div>
      {error ? <StyledError>{error}</StyledError> : null}
    </StyledDiv>
  );
};

CustomInput.propTypes = {
  error: PropTypes.string,
  inputBoxClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

CustomInput.defaultProps = {
  inputBoxClass: '',
  name: '',
  label: '',
  error: '',
};

export default CustomInput;
