import React from 'react';
import { PropTypes } from 'prop-types';
import Sidebar from 'containers/Sidebar/index.js';
import { MarketingPortal, PageFormat } from './styles.js';
const Layout = ({ showSideBar, component }) => {
  return (
    <>
      <MarketingPortal>
        <PageFormat>
          {showSideBar && <Sidebar />}
          {component}
        </PageFormat>
      </MarketingPortal>
    </>
  );
};

Layout.propTypes = {
  showSideBar: PropTypes.bool,
  component: PropTypes.node.isRequired,
};

PropTypes.defaultProps = {
  showSideBar: false,
};
export default Layout;
