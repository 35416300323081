import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Campaign from 'containers/Campaign';
import Insights from 'containers/Insights';
import PopUp from 'pages/PopUp';
import Layout from './layout';
import CampaignView from 'containers/CampaignView';
import CampaignEdit from 'containers/CampaignEdit';

const RouteComponent = () => {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={<Navigate to="campaigns" replace={true} />}
      />
      <Route
        index
        path="campaigns"
        element={<Layout component={<Campaign />} showSideBar />}
      />
      <Route path="campaigns/add" exact element={<PopUp />} />
      <Route path="campaign/:id" exact element={<CampaignView />} />
      <Route path="campaign/edit/:id" exact element={<CampaignEdit />} />
      <Route
        index
        path="insights"
        element={<Layout component={<Insights />} showSideBar />}
      />
    </Routes>
  );
};

export default RouteComponent;
