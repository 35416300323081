import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  width: 100%;
  padding: 2%;
  font-family: ${({ theme }) => theme.fonts.primary};
  flex-direction: column;
  font-style: normal;
`;
