import { post, get } from 'utils/api';
import {
  USER_LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_TOKEN,
} from 'utils/URLs';

export const login = (data) => post(USER_LOGIN, data);
export const forgotPassword = (data) => post(FORGOT_PASSWORD, data);
export const resetPassword = (data) => post(RESET_PASSWORD, data);
export const validateToken = (token) => get(`${VALIDATE_TOKEN}/${token}`);
