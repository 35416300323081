import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Button from '@mui/material/Button';
import xButton from 'images/xButton.svg';
import BackButton from 'images/BackButton.svg';
import CampaignRules from 'components/CampaignRules';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import DatePicker from 'components/DatePicker';
import { customersOptions } from 'utils/constants';
import {
  checkRequiredField,
  checkAllRequiredFieldsWithKey,
  handleServerErrors,
} from 'utils/FormValidation';
import { createCampaign, updateCampaign } from 'apiServices/issuer';
import Loader from 'components/Loader';
import IssuerContext from 'pages/IssuerDashboard/IssuerContext';
import {
  OverallBox,
  PopUpHeader,
  PopUpHeaderText,
  HeaderLeft,
  BlueHeader,
  DetailsOrg,
  GoalOrg,
  CompleteCampaignButton,
  TypeCampaignNameOutline,
  IncreaseByText,
  CustomersEngagedText,
  CustomersEngagedDropdownOrg,
} from './styles';

const InitialError = {
  name: '',
  goal_id: '',
  rule_id: '',
  start_date: '',
  end_date: '',
  increase_by_customers: '',
  location: '',
};
const pendingCampaign = 1;
const CreateCampaign = ({ campaignData, changePopUp, isEdit = false }) => {
  const {
    issuer: { venues = [] },
  } = useContext(IssuerContext);
  const [campaign, setCampaign] = useState({
    ...campaignData.data,
    venues,
  });
  const [errors, setErrors] = useState(InitialError);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setCampaign({ ...campaign, [name]: value });
  };

  const changeStartDate = (newStartDate) => {
    const start_date = moment(newStartDate).format('yyyy-MM-DD');
    setCampaign({ ...campaign, start_date });
  };

  const changeEndDate = (newEndDate) => {
    const end_date = moment(newEndDate).format('yyyy-MM-DD');
    setCampaign({ ...campaign, end_date });
  };

  const handleNumericChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value >= 0 && value <= 100000) {
      handleChange(event);
    }
  };

  const locationOptions = [
    {
      text: 'All locations',
      value: 0,
    },
    ...campaign.venues.map((venue) => ({
      text: venue.name,
      value: venue.venue_id,
    })),
  ];

  const setError = ({ target: { name, value } }) => {
    setErrors({ ...errors, ...checkRequiredField(name, value) });
  };

  const removeErrors = ({ target: { name } }) => {
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    const errorObject = {
      ...checkAllRequiredFieldsWithKey(InitialError, campaign),
    };
    setErrors({ ...errors, ...errorObject });
    return Object.values(errorObject).every((errorMsg) => !errorMsg);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      if (!isEdit) {
        return createCampaign(campaign)
          .then(({ data }) => {
            changePopUp({
              step: 2,
              data: data,
            });
          })
          .catch(({ errors: apiErrors }) => {
            setErrors({ ...errors, ...handleServerErrors(apiErrors) });
          })
          .finally(() => {
            setLoading(false);
          });
      }
      updateCampaign(campaign.id, campaign)
        .then(({ data }) => {
          changePopUp({
            step: 2,
            data: data,
          });
        })
        .catch(({ errors: apiErrors }) => {
          setErrors({ ...errors, ...handleServerErrors(apiErrors) });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const {
    name,
    start_date,
    end_date,
    customers_engaged,
    location,
    increase_by_customers,
  } = campaign;
  return (
    <OverallBox>
      <PopUpHeader>
        <HeaderLeft>
          <img
            src={BackButton}
            onClick={() =>
              changePopUp({
                step: 0,
                data: { ...campaign },
              })
            }
          />
          <PopUpHeaderText>Determine your campaign rules</PopUpHeaderText>
        </HeaderLeft>
        <Link to="/issuer">
          <img src={xButton} />
        </Link>
      </PopUpHeader>
      <BlueHeader>Details</BlueHeader>
      <DetailsOrg>
        <TypeCampaignNameOutline>
          <CustomInput
            type="text"
            name="name"
            value={name}
            inputBoxClass="campaign-name-input"
            placeholder="Type Campaign Name"
            onChange={handleChange}
            error={errors.name}
            onBlur={setError}
            onFocus={removeErrors}
          />
        </TypeCampaignNameOutline>
        <div className="campaign-date">
          <div className="datePickerBox">
            <DatePicker
              label="Start Date"
              inputFormat="dd/MM/yyyy"
              value={start_date}
              onChange={(val) => {
                removeErrors({ target: { name: 'start_date' } });
                changeStartDate(val);
              }}
              disablePast
              disabled={isEdit && campaign.status != pendingCampaign}
              error={errors.start_date}
            />
          </div>
          <div className="datePickerBox">
            <DatePicker
              label="End Date"
              inputFormat="dd/MM/yyyy"
              value={end_date}
              onChange={(val) => {
                removeErrors({ target: { name: 'end_date' } });
                changeEndDate(val);
              }}
              disablePast
              minDate={start_date}
              error={errors.end_date}
              onBlur={setError}
            />
          </div>
        </div>
      </DetailsOrg>
      {loading && <Loader />}
      <BlueHeader>Rules</BlueHeader>
      <CampaignRules
        campaign={campaign}
        handleChange={handleChange}
        updateCampaign={setCampaign}
        handleNumericChange={handleNumericChange}
        errors={errors}
        setError={setError}
        removeErrors={removeErrors}
        disabled={isEdit && campaign.status != pendingCampaign}
      />
      <BlueHeader>Goal:</BlueHeader>
      <GoalOrg>
        <IncreaseByText>Increase By</IncreaseByText>
        <CustomInput
          type="number"
          placeholder="Type No. Customers"
          inputBoxClass="increase-by"
          name="increase_by_customers"
          value={increase_by_customers}
          onChange={handleNumericChange}
          error={errors.increase_by_customers}
          onBlur={setError}
          onFocus={removeErrors}
        />
        <CustomersEngagedText>Customers Engaged</CustomersEngagedText>
        <CustomersEngagedDropdownOrg>
          <CustomSelect
            id="customers-engaged"
            name="customers_engaged"
            value={customers_engaged}
            onChange={handleChange}
            menuItems={customersOptions}
            error={errors.customers_engaged}
            onBlur={setError}
            onFocus={removeErrors}
          />
          <CustomersEngagedText>Locations</CustomersEngagedText>
          <CustomSelect
            title="Select Locations"
            id="location"
            name="location"
            value={location}
            onChange={handleChange}
            menuItems={locationOptions}
            error={errors.location}
            onBlur={setError}
            onFocus={removeErrors}
          />
        </CustomersEngagedDropdownOrg>
      </GoalOrg>
      <CompleteCampaignButton>
        <Button
          onClick={handleSubmit}
          className="submit-camapign-button"
          disabled={loading}
        >
          {isEdit ? 'Update' : 'Complete'} Campaign
        </Button>
      </CompleteCampaignButton>
    </OverallBox>
  );
};

CreateCampaign.propTypes = {
  changePopUp: PropTypes.func.isRequired,
  campaignData: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
};

export default CreateCampaign;
