import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
  Popup,
  PopupHeader,
  PopupText,
  PopupSubText,
  ButtonHolder,
  StyledError,
  StyledButton,
} from './styles.js';
import Button from 'components/Button/index.js';
const CampaignVerification = ({
  open,
  handleClose,
  text,
  subText = '',
  primaryButtonClick,
  secondaryButtonClick,
  error,
}) => {
  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onBackdropClick={handleClose}
        classes={{ paper: 'verification-modal' }}
      >
        <Popup>
          <PopupHeader>
            <DialogTitle id="customized-dialog-title">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </PopupHeader>
          <PopupText>
            {text} {error && <StyledError>{error}</StyledError>}
          </PopupText>
          <PopupSubText>{subText}</PopupSubText>
          <PopupSubText>
            Note: You can only pause a campaign twice in a day.
          </PopupSubText>
          <ButtonHolder>
            <StyledButton>
              <Button
                buttonType="primary"
                className="yesNoButton"
                onClick={primaryButtonClick}
              >
                Confirm
              </Button>
            </StyledButton>
            <StyledButton>
              <Button buttonType="secondary" onClick={secondaryButtonClick}>
                Cancel
              </Button>
            </StyledButton>
          </ButtonHolder>
        </Popup>
      </Dialog>
    </>
  );
};

CampaignVerification.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  primaryButtonClick: PropTypes.func.isRequired,
  secondaryButtonClick: PropTypes.func.isRequired,
  error: PropTypes.string,
  subText: PropTypes.string,
};

export default CampaignVerification;
