import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  LoginContainer,
  CenterBox,
  StyledInputBox,
  ButtonBox,
} from './styles.js';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { login } from 'apiServices/auth';
import {
  getStorageData,
  setStorageData,
  handleApiErrors,
} from 'utils/helper.js';

const initialData = {
  email: '',
  password: '',
};
const Login = () => {
  const navigate = useNavigate();
  const user = getStorageData('user');

  useEffect(() => {
    if (user.id) {
      navigate('/issuer', { replace: true });
    }
  }, []);

  const handleSubmit = async (values, { setErrors }) => {
    login(values)
      .then(({ data }) => {
        setStorageData('user', data);
        navigate('/issuer', { replace: true });
      })
      .catch(({ errors }) => {
        setErrors(handleApiErrors(errors));
      });
  };
  return (
    <Formik
      initialValues={initialData}
      validationSchema={Yup.object({
        email: Yup.string()
          .required('This field is required.')
          .email('Please enter a valid email address.'),
        password: Yup.string()
          .required('This field is required.')
          .min(6, 'Password must be at least 6 characters long'),
      })}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <LoginContainer>
            <CenterBox>
              <StyledInputBox>
                <InputField
                  id="email"
                  name="email"
                  placeholder="janedoe@gmail.com"
                  label="Email"
                />
              </StyledInputBox>

              <StyledInputBox>
                <InputField
                  id="password"
                  name="password"
                  type="password"
                  placeholder="••••••••"
                  label="Password"
                />
              </StyledInputBox>
              <ButtonBox>
                <Button
                  data-testid="signIn"
                  buttonType="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                >
                  SIGN IN
                </Button>
                <Link to="/forgot-password">Forgot password</Link>
              </ButtonBox>
            </CenterBox>
          </LoginContainer>
        </Form>
      )}
    </Formik>
  );
};
export default Login;
