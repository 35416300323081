import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import ErrorBoundary from './components/ErrorBoundry';
import Home from './pages/Home';
import IssuerDashboard from './pages/IssuerDashboard';
import Header from 'components/Header';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import PopUp from 'pages/PopUp/index.js';
import NotFoundPage from 'pages/NotFound';
class App extends React.PureComponent {
  render() {
    return (
      <>
        <Router>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="" element={<Header />}>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/login" element={<Home />} />
                  <Route exact path="/issuer/*" element={<IssuerDashboard />} />
                  <Route
                    exact
                    path="/forgot-password"
                    element={<ForgotPassword />}
                  />
                  <Route
                    exact
                    path="/reset-password/:token"
                    element={<ResetPassword />}
                  />
                  <Route exact path="/add-camapaign" element={<PopUp />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Routes>
            </ThemeProvider>
          </ErrorBoundary>
        </Router>
      </>
    );
  }
}

export default App;
