import React from 'react';
import PropTypes from 'prop-types';
import { StyledDiv, StyledError } from './styles.js';
import { useField } from 'formik';
import { TextField } from '@mui/material';

const InputField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <StyledDiv>
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="fullwidth">
        <TextField className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <StyledError>{meta.error}</StyledError>
        ) : null}
      </div>
    </StyledDiv>
  );
};

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default InputField;
