import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SideBar, Tab, TabText } from './styles.js';
import cs from 'classnames';

function Sidebar() {
  const navigate = useNavigate();
  const url = useLocation();
  const activeTab = url.pathname;

  return (
    <SideBar>
      <Tab
        onClick={() => navigate('/issuer/campaigns')}
        className={cs({ active: activeTab !== '/issuer/insights' })}
      >
        <TabText>Campaigns</TabText>
      </Tab>
      <Tab
        onClick={() => navigate('/issuer/insights')}
        className={cs({ active: activeTab === '/issuer/insights' })}
      >
        <TabText>Insights</TabText>
      </Tab>
    </SideBar>
  );
}

export default Sidebar;
