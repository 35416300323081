export const getStorageData = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key)) || {};
  } catch (error) {
    return {};
  }
};

export const setStorageData = (key, data) => {
  const dataTemp = JSON.stringify(data);
  window.localStorage.setItem(key, dataTemp);
};

export const handleApiErrors = (apiErrors) =>
  Object.entries(apiErrors).reduce(
    (acc, [key, [value]]) => ({ ...acc, [key]: value }),
    {},
  );
