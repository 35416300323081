import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import xButton from 'images/xButton.svg';
import BackButton from 'images/BackButton.svg';
import CampaignRules from 'components/CampaignRules';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import DatePicker from 'components/DatePicker';
import { customersOptions } from 'utils/constants';
import { checkRequiredField } from 'utils/FormValidation';
import { getCampaign } from 'apiServices/issuer';
import Loader from 'components/Loader';
import IssuerContext from 'pages/IssuerDashboard/IssuerContext';
import {
  OverallBox,
  CampaignBox,
  PopUpHeader,
  PopUpHeaderText,
  HeaderLeft,
  BlueHeader,
  DetailsOrg,
  GoalOrg,
  TypeCampaignNameOutline,
  IncreaseByText,
  CustomersEngagedText,
  CustomersEngagedDropdownOrg,
} from './styles';

const InitialError = {
  name: '',
  goal_id: '',
  rule_id: '',
  start_date: '',
  end_date: '',
  increase_by_customers: '',
  location: '',
};

const CampaignView = () => {
  const {
    issuer: { venues = [] },
  } = useContext(IssuerContext);

  const [campaign, setCampaign] = useState({
    venues,
    name: '',
    user_id: '',
    goal_id: '',
    rule_id: '',
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).add(1, 'd').format('YYYY-MM-DD'),
    increase_by_customers: '',
    customers_engaged: 1,
    location: '',
    spent_amount: '',
    days_active: [],
    number_of_times: '',
    start_time: '',
    end_time: '',
    status: 1,
  });
  const [errors, setErrors] = useState(InitialError);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    getCampaign(id)
      .then(({ data }) =>
        setCampaign({
          ...campaign,
          ...data,
          days_active: JSON.parse(data.days_active),
        }),
      )
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setCampaign({ ...campaign, [name]: value });
  };

  const changeStartDate = (newStartDate) => {
    setCampaign({ ...campaign, start_date: newStartDate });
  };

  const changeEndDate = (newEndDate) => {
    setCampaign({ ...campaign, end_date: newEndDate });
  };

  const handleNumericChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value >= 0 && value <= 100000) {
      handleChange(event);
    }
  };

  const locationOptions = [
    {
      text: 'All locations',
      value: 0,
    },
    ...campaign.venues.map((venue) => ({
      text: venue.name,
      value: venue.venue_id,
    })),
  ];

  const setError = ({ target: { name, value } }) => {
    setErrors({ ...errors, ...checkRequiredField(name, value) });
  };
  const removeErrors = ({ target: { name } }) => {
    setErrors({ ...errors, [name]: '' });
  };

  const {
    name,
    start_date,
    end_date,
    customers_engaged,
    location,
    increase_by_customers,
  } = campaign;
  return (
    <OverallBox>
      <CampaignBox>
        <PopUpHeader>
          <HeaderLeft>
            <img
              src={BackButton}
              onClick={() => {
                navigate('/issuer');
              }}
            />
            <PopUpHeaderText>Determine your campaign rules</PopUpHeaderText>
          </HeaderLeft>
          <Link to="/issuer">
            <img src={xButton} />
          </Link>
        </PopUpHeader>
        <BlueHeader>Details</BlueHeader>
        <DetailsOrg>
          <TypeCampaignNameOutline>
            <CustomInput
              type="text"
              name="name"
              value={name}
              inputBoxClass="campaign-name-input"
              placeholder="Type Campaign Name"
              onChange={handleChange}
              error={errors.name}
              onBlur={setError}
              onFocus={removeErrors}
              disabled
            />
          </TypeCampaignNameOutline>

          <DatePicker
            label="Start Date"
            inputFormat="dd/MM/yyyy"
            value={start_date}
            onChange={changeStartDate}
            disablePast
            maxDate={end_date}
            disabled
          />
          <DatePicker
            label="End Date"
            inputFormat="dd/MM/yyyy"
            value={end_date}
            onChange={changeEndDate}
            disablePast
            minDate={start_date}
            disabled
          />
        </DetailsOrg>
        {loading && <Loader />}
        <BlueHeader>Rules</BlueHeader>
        <CampaignRules
          campaign={campaign}
          handleChange={handleChange}
          updateCampaign={setCampaign}
          handleNumericChange={handleNumericChange}
          errors={errors}
          setError={setError}
          removeErrors={removeErrors}
          disabled
        />
        <BlueHeader>Goal:</BlueHeader>
        <GoalOrg>
          <IncreaseByText>Increase By</IncreaseByText>
          <CustomInput
            type="number"
            placeholder="Type No. Customers"
            inputBoxClass="increase-by"
            name="increase_by_customers"
            value={increase_by_customers}
            onChange={handleNumericChange}
            error={errors.increase_by_customers}
            onBlur={setError}
            onFocus={removeErrors}
            disabled
          />
          <CustomersEngagedText>Customers Engaged</CustomersEngagedText>
          <CustomersEngagedDropdownOrg>
            <CustomSelect
              id="customers-engaged"
              name="customers_engaged"
              value={customers_engaged}
              onChange={handleChange}
              menuItems={customersOptions}
              error={errors.customers_engaged}
              onBlur={setError}
              onFocus={removeErrors}
              disabled
            />
            <CustomersEngagedText> Locations</CustomersEngagedText>
            <CustomSelect
              title="Select Locations"
              id="location"
              name="location"
              value={location}
              onChange={handleChange}
              menuItems={locationOptions}
              error={errors.location}
              onBlur={setError}
              onFocus={removeErrors}
              disabled
            />
          </CustomersEngagedDropdownOrg>
        </GoalOrg>
      </CampaignBox>
    </OverallBox>
  );
};

export default CampaignView;
