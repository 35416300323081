import styled from 'styled-components';

export const OverallBox = styled.div`
    display: flex;
    flex-direction: column;
    background:linear-gradient(180deg, white 15%, #F2F2F2 0.1%, #F2F2F2 85%);
    width: 950px;
    min-height: 600px;
    border-radius: 14px;
    align-items: center;
`;

export const PopUpHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
    width: 90%;
`;

export const PopUpHeaderText = styled.div`
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 22px;
    color: ${({ theme }) => theme.colors.black};
`;

export const InsideBox = styled.div`
    margin-top: 73px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    width: 690px;
    min-height: 400px;
    border-radius: 12px;
    font-family: ${({ theme }) => theme.fonts.primary};
    text-align: center;
    justify-content: center;
    flex-direction: column;
    `;

export const CongratsMessage = styled.div`
  justify-content: center;
  width: 581px;
  align-items: center;
  color: ${({ theme }) => theme.colors.realLyfe};
  font-size: 30px;
  display: flex;
  margin-bottom: 30px;
`;

export const CampaignDates = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.otherGray};
  font-size: 20px;
  font-style: italic;
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 30px;

`;
export const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;
