import React from 'react';
import PropTypes from 'prop-types';
import { StyledDiv, StyledError } from './styles.js';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from 'styled-components';

const CustomSelect = ({ error, menuItems, inputBoxClass, ...props }) => {
  const theme = useTheme();
  return (
    <StyledDiv>
      <div className={inputBoxClass}>
        <Select
          className="customSelect"
          sx={{
            backgroundColor: theme.colors.white,
            fontSize: '18px',
            color: theme.colors.black,
            fontWeight: '500',
            width: '200px',
          }}
          MenuProps={{
            sx: {
              fontfamily: theme.fonts.primary,
            },
          }}
          {...props}
        >
          {menuItems.map(({ text, value, disabled }) => (
            <MenuItem key={value} value={value} disabled={disabled}>
              {text}
            </MenuItem>
          ))}
        </Select>
        {error ? <StyledError>{error}</StyledError> : null}
      </div>
    </StyledDiv>
  );
};

CustomSelect.propTypes = {
  menuItems: PropTypes.array.isRequired,
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  inputBoxClass: PropTypes.string,
  varient: PropTypes.string,
};

export default CustomSelect;
