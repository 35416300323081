import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorageData } from 'utils/helper';
import Routes from './routes';
import IssuerContext from './IssuerContext';
import { getPersonalisation } from 'apiServices/issuer';

const IssuerDashboard = () => {
  const navigate = useNavigate();
  const [issuer, setIssuer] = useState({});

  useEffect(() => {
    const user = getStorageData('user');
    if (!user.id) {
      navigate('/');
    }
    getPersonalisation(user.id)
      .then(({ data }) => {
        setIssuer(data);
      })
      .catch(() => {});
  }, []);

  const updateIssuer = (data) => {
    setIssuer({ ...issuer, ...data });
  };
  return (
    <IssuerContext.Provider value={{ issuer, updateIssuer }}>
      <Routes />
    </IssuerContext.Provider>
  );
};

export default IssuerDashboard;
