import React from 'react';
import styled from 'styled-components';
import Login from 'containers/Login';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  height: 100vh;
`;
const Home = () => {
  return (
    <StyledContainer>
      <Login />
    </StyledContainer>
  );
};

export default Home;
