import { create } from 'axios';
import { API_URL } from 'utils/env';
import { getStorageData } from 'utils/helper';
import { serverErrors } from './handleServerError';

const baseURL = `${API_URL}/api/v1`;
export const axios = create({
  baseURL,
  headers: { common: { 'Content-Type': 'application/json' } },
});

axios.interceptors.response.use(
  (response) => successResponce(response),
  (error) => handleErrors(error),
);

axios.interceptors.request.use(
  (config) => {
    const request = config;
    const user = getStorageData('user');
    if (user && user.token) {
      request.headers.common['Authorization'] = `Bearer ${user.token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);
const successResponce = (result) => {
  const { data = {} } = result;
  return data;
};
const handleErrors = (error) => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    window.localStorage.clear();
    window.location = '/login';
  }
  return Promise.reject(serverErrors(error));
};
