export const USER_LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const GET_GOALS = 'get-goals';
export const PERSONALISATION = 'personalisation';
export const CAMPAIGN = 'campaign';
export const GET_CAMPAIGN = 'get-campaign';
export const CAMPAIGN_SUMMARY = 'campaign-summary';
export const CAMPAIGN_STATUS = 'campaign-status';
export const VALIDATE_TOKEN = 'validate-token';
