export const ENV = {
  DEV: 'DEV',
  UAT: 'UAT',
  PRODUCTION: 'PRODUCTION',
  TEST: 'TEST',
};

export const ENV_ORIGINS = {
  [ENV.DEV]: ['http://localhost:3000'],
  [ENV.TEST]: ['http://localhost:4000'],
  [ENV.UAT]: ['https://uat-lyfe-marketing.herokuapp.com'],
  [ENV.PRODUCTION]: [
    'http://rewards.lyfetech.io',
    'https://rewards.lyfetech.io',
  ],
};
const startsWith = (string) => (beginning) =>
  new RegExp(`^${beginning}`).test(string);

export const getEnv = (url = window.location.href) =>
  Object.keys(ENV_ORIGINS).find((key) =>
    ENV_ORIGINS[key].find(startsWith(url)),
  ) || ENV.DEV;

export const API_URL = {
  [ENV.DEV]: 'http://210.212.1.1',
  [ENV.TEST]: 'https://uat-lyfe-marketing-api.herokuapp.com',
  [ENV.UAT]: 'https://uat-lyfe-marketing-api.herokuapp.com',
  [ENV.PRODUCTION]: 'https://prod-lyfe-marketing-api.herokuapp.com',
}[getEnv()];
