import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/index.js';
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import {
  OverallBox,
  PopUpHeader,
  PopUpHeaderText,
  InsideBox,
  CongratsMessage,
  CampaignDates,
  ButtonHolder,
} from './styles.js';
import xButton from 'images/xButton.svg';
import PartyRight from 'images/party_right.svg';
import PartyLeft from 'images/party_left.svg';

function CampaignConfirmation({ campaign, isEdit }) {
  const navigate = useNavigate();
  return (
    <OverallBox>
      <PopUpHeader>
        <PopUpHeaderText></PopUpHeaderText>
        <Link to="/issuer">
          <img src={xButton} />
        </Link>
      </PopUpHeader>
      <InsideBox>
        <CongratsMessage>
          <img src={PartyLeft} />
          Congratulations, your campaign has been{' '}
          {isEdit ? 'updated' : 'created'}!
          <img src={PartyRight} />
        </CongratsMessage>
        <CampaignDates>
          Your campaign will run from{' '}
          {moment(campaign.data.start_date).format('DD/MM/YYYY')} to{' '}
          {moment(campaign.data.end_date).format('DD/MM/YYYY')}
        </CampaignDates>
        <ButtonHolder>
          {isEdit ? (
            <Button buttonType="congrats" onClick={() => navigate('/issuer/')}>
              Campaigns
            </Button>
          ) : (
            <Button
              buttonType="congrats"
              onClick={() => navigate(`/issuer/campaign/${campaign.data.id}`)}
            >
              View Campaign
            </Button>
          )}

          <Button
            buttonType="congrats"
            onClick={() => navigate('/issuer/insights')}
          >
            View Insights
          </Button>
        </ButtonHolder>
      </InsideBox>
    </OverallBox>
  );
}

CampaignConfirmation.propTypes = {
  campaign: PropTypes.object.isRequired,
  changePopUp: PropTypes.any.isRequired,
  isEdit: PropTypes.bool,
};

export default CampaignConfirmation;
