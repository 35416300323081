import styled from 'styled-components';

export const SideBar = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.gradients.darkBlue};
  width: 280px;
  flex-direction: column;
  min-height: 91.35vh;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Tab = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkLyfe2};
  width: 100%;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightLyfe};
    border: 1px solid ${({ theme }) => theme.colors.darkLyfe};
  }
  &.active {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightLyfe};
  }
`;

export const TabText = styled.div`
  margin: 10%;
  display: flex;
`;
