import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CampaignStatus from 'components/CampaignStatus';
import CampaignSummaryTable from 'components/CampaignSummaryTable';
import {
  getCampaignlist,
  getCampaignSummary,
  updateCampaignStatus,
} from 'apiServices/issuer';
import {
  CampaignPage,
  NewCampaignButton,
  CampaignText,
  SummaryHeader,
  SummaryCanvas,
  LastThirty,
  LastThirtyText,
  AllCampaignsHeader,
  AllCampaignsHeaderFormat,
  SummaryComponents,
} from './styles.js';
import { getStorageData } from 'utils/helper.js';
import { statusConst, campaignStatusFilter } from 'utils/constants';
import CustomSelect from 'components/CustomSelect/index.js';
import Loader from 'components/Loader';
import CampaignVerification from 'components/CampaignVerification';

const InitialModal = { modal: false, campaignData: {}, errorMessage: '' };

const Campaign = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsSummary, setCampaignsSummary] = useState({
    customers_engaged: 0,
    loyalty_rewards: 0,
    new_customers: 0,
    revenue: 0,
  });
  const [filters, setFilters] = useState({ status: 0 });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [statusModel, setStatusModel] = useState(InitialModal);

  const user = getStorageData('user');
  const today = moment(new Date());
  const getDaysLeft = (endDate) => {
    endDate = moment(endDate);
    return endDate.diff(today, 'days');
  };
  useEffect(() => {
    getCampaigns();
    getcampaignSummaryData();
  }, []);
  const navigate = useNavigate();
  const handleRedirect = () => navigate('/issuer/insights');

  const getCampaigns = (params) => {
    setLoading(true);
    getCampaignlist(user.id, {
      start_date: moment(today).subtract(30, 'days').format('YYYY-MM-DD'),
      end_date: today.format('YYYY-MM-DD'),
      ...params,
    })
      .then(({ data }) => {
        setCampaigns(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const getcampaignSummaryData = () => {
    setLoading(true);
    getCampaignSummary(user.key, {
      start_date: moment(today).subtract(30, 'days').format('YYYY-MM-DD'),
      end_date: today.format('YYYY-MM-DD'),
    })
      .then(({ data }) => {
        setCampaignsSummary(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const filterCampaigns = ({ target: { name, value } }) => {
    setFilters({ ...filters, [name]: value });
    getCampaigns({ [name]: value });
  };

  const handleUpdateStatus = (campaignData) => {
    setStatusModel({
      ...statusModel,
      modal: true,
      campaignData,
    });
  };

  const lastThirty = () => {
    const lastDate = moment(today).subtract(30, 'days');
    return lastDate.format('Do MMMM YYYY');
  };

  const cancelStatusUpdate = () => {
    setStatusModel(InitialModal);
  };
  const updateStatus = () => {
    updateCampaignStatus(statusModel.campaignData.id, {
      status:
        statusModel.campaignData.status === statusConst.active
          ? statusConst.paused
          : statusConst.active,
    })
      .then(() => {
        getCampaigns();
        cancelStatusUpdate();
      })
      .catch(({ message }) => {
        setStatusModel({ ...statusModel, errorMessage: message });
      });
  };

  const { modal, campaignData, errorMessage } = statusModel;
  return (
    <CampaignPage>
      <SummaryHeader>
        <div>Campaign Summary</div>
        <NewCampaignButton onClick={() => navigate('add')}>
          <CampaignText>New Campaign</CampaignText>
        </NewCampaignButton>
      </SummaryHeader>

      <SummaryCanvas>
        <LastThirty>
          <LastThirtyText>
            Last 30 days: &nbsp;
            {lastThirty()} - {today.format('Do MMMM YYYY')}
          </LastThirtyText>
        </LastThirty>
        <SummaryComponents>
          <CampaignStatus
            title="Revenue"
            value={`£${campaignsSummary.revenue}`}
            handleClick={handleRedirect}
          />
          <CampaignStatus
            title="Loyalty Reward"
            value={`£${campaignsSummary.loyalty_rewards}`}
            handleClick={handleRedirect}
          />
          <CampaignStatus
            title="Customers Engaged"
            value={campaignsSummary.customers_engaged}
            handleClick={handleRedirect}
          />
          <CampaignStatus
            title="New Customers"
            value={campaignsSummary.new_customers}
            handleClick={handleRedirect}
          />
        </SummaryComponents>
      </SummaryCanvas>
      <AllCampaignsHeaderFormat>
        <AllCampaignsHeader>All Campaigns</AllCampaignsHeader>
        <CustomSelect
          title="Select Locations"
          id="filter_campaigns"
          name="status"
          value={filters.status}
          onChange={filterCampaigns}
          menuItems={campaignStatusFilter}
          classes={{ root: 'filter-status' }}
        />
      </AllCampaignsHeaderFormat>
      <CampaignSummaryTable
        campaigns={campaigns}
        campaignNavigate={(url) => navigate(url)}
        updateStatus={(campaign) => handleUpdateStatus(campaign)}
      />

      <CampaignVerification
        open={modal}
        text={`Are you sure you want to ${
          campaignData && campaignData.status == statusConst.active
            ? 'pause'
            : 'resume'
        } this campaign?`}
        subText={`${
          campaignData && campaignData.status == statusConst.active
            ? 'Pausing your campaign will not allow customers to receive rewards for their transactions. The campaign end date will remain the same.'
            : 'Activating your campaign will allow customers to receive rewards for their transactions.'
        }`}
        primaryButtonClick={updateStatus}
        secondaryButtonClick={cancelStatusUpdate}
        handleClose={cancelStatusUpdate}
        error={errorMessage}
      />
    </CampaignPage>
  );
};

export default Campaign;
