import {
  StyledHeader,
  Logo,
  CompanyName,
  Settings,
  Question,
} from './styles.js';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Outlet } from 'react-router-dom';
import cs from 'classnames';
import LyfeLogo from 'images/LyfeLogo.svg';
import SettingsIcon from 'images/SettingsIcon.png';
import QuestionIcon from 'images/QuestionIcon.png';
import { getStorageData } from 'utils/helper.js';
import Button from 'components/Button';

const Header = () => {
  const user = getStorageData('user');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSettings = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    window.localStorage.removeItem('user');
    navigate('/', { replace: true });
  };
  return (
    <>
      <StyledHeader>
        <Logo>
          <img src={LyfeLogo} alt="Logo" />
        </Logo>
        {user && user.id && (
          <>
            <CompanyName>{user.name}</CompanyName>
            <Settings>
              <img
                src={SettingsIcon}
                onClick={handleSettings}
                alt="Settings"
                width="25px"
              />
              <div
                className={cs('logout-pane', {
                  visible: isOpen,
                  hidden: !isOpen,
                })}
              >
                <Button
                  buttonType="transparent"
                  onClick={logout}
                  className="transparent-button"
                >
                  Logout
                </Button>
              </div>
            </Settings>
            <Question>
              <img src={QuestionIcon} alt="Question" width="25px" />
            </Question>
          </>
        )}
      </StyledHeader>
      <Outlet />
    </>
  );
};

Header.propTypes = {
  classes: PropTypes.object,
};

export default Header;
