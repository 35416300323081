import styled from 'styled-components';

export const CampaignPage = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  width: 100%;
  padding: 2% 6%;
  font-family: ${({ theme }) => theme.fonts.primary};
  flex-direction: column;
  font-style: normal;
`;

export const NewCampaignButton = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.realLyfe};
  width: 200px;
  border-radius: 9px;
  justify-content: center;
  display: flex;
  :hover {
    background-color: ${({ theme }) => theme.colors.darkLyfe};
    cursor: pointer;
  }
`;

export const CampaignText = styled.div`
  margin-top: 7.5%;
  margin-bottom: 7.5%;
`;
export const SummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
`;

export const SummaryCanvas = styled.div`
  background-color: ${({ theme }) => theme.colors.midDarkGray};
  margin-top: 1%;
  border-radius: 12px;
`;

export const LastThirty = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 500;
  font-size: 14px;
  width: 30%;
  background-color: ${({ theme }) => theme.colors.midGray};
  border-radius: 9px;
  margin: 1%;
  margin-left: 1.5%;
  @media only screen and (max-width: 1366px) {
    width: 38%;
    justify-content: left;
  }
`;

export const LastThirtyText = styled.div`
  margin: 2% 0;
  padding: 8px 12px;
  line-height: 17px;
`;

export const AllCampaignsHeader = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-right: 20px;
`;

export const AllCampaignsHeaderFormat = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3%;
  & .filter-select-menu{
    backgroundColor: 'white',
    fontFamily: 'Josefin Sans',
    fontSize: '18px',
    color: '#373737',
    fontWeight: '500',
    width: '200px',
 }
`;

export const AllCampaignsCanvas = styled.div`
  background-color: ${({ theme }) => theme.colors.midDarkGray};
  margin-top: 1%;
  height: 36vh;
  border-radius: 14px;
`;

export const SummaryComponents = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.5%;
  justify-content: space-between;
`;

export const FilterFormat = styled.div`
  width: 13%;
  font-size: 12px;
  margin-left: 1.6%;
  height: 3.2vh;
  margin-top: 1.2%;
`;

export const AllCampaignsCategories = styled.div`
  display: flex;
  flex-direction: row;
  width: 96%;
  margin-left: 1.6%;
  background-color: ${({ theme }) => theme.colors.midGray};
  border-radius: 7px;
  height: 6vh;
  margin-top: 1vh;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  align-items: center;
`;

export const StatusFormat = styled.div`
  margin-left: 4.25%;
  font-weight: 600;
`;

export const CampaignNameFormat = styled.div`
  margin-left: 6%;
  font-weight: 600;
`;

export const GoalProgressFormat = styled.div`
  margin-left: 24%;
  font-weight: 600;
`;

export const DaysLeftFormat = styled.div`
  margin-left: 10.5%;
  font-weight: 600;
`;

export const CostFormat = styled.div`
  margin-left: 9.5%;
  font-weight: 600;
`;

export const AllCampaignsSummaries = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5%;
  width: 98.5%;
  height: 26.4vh;
  margin-top: 0.5vh;
  margin-bottom: 2vh;
  white-space: nowrap;
  overflow-y: scroll;
`;
