import styled from 'styled-components';

export const RulesOrg = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 92.5%;
  height: 170px;
  align-items: center;
  flex-direction: column;
  padding: 12px;
`;

export const InsertRuleOrg = styled.div`
  display: flex;
  justify-content: space-between;
  width: 98%;
  height: 50px;
  align-items: center;
  margin-top: 2%;
`;

export const InsertRuleDesc = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  width: 156px;
`;

export const InsertRule = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  border: 0.5px solid rgba(0, 0, 0, 0.39);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 675px;
  height: 50px;
`;

export const RuleCategoriesOrg = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 2%;
  & .whiteBox {
    background: ${({ theme }) => theme.colors.white};
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    & input {
      border-radius: 12px;
      background-color: ${({ theme }) => theme.colors.lightGray};
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.black};
      width: 150px;
      height: 24px;
      padding: 16.5px 8px;
    }
  }
  & ::placeholder {
    display: flex;
    color: ${({ theme }) => theme.colors.black};
    font-style: italic;
    white-space: initial;
    align-items: center;
    justify-content: center;
  }
  & input:focus::placeholder {
    color: transparent;
  }
  & .days-active-select {
    padding: 8px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
  }
  & .selectBox {
    width: 210px;
    & input {
      border-radius: 12px;
    }
  }
`;

export const RuleCategories = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  height: 70px;
  width: 208.5px;
  margin-left: 1%;
`;

export const StyledTime = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  width: 210px;
  padding: 10px 0;
  justify-content: space-around;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.black};
  & .time {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & label {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 13px;
  }
  & input {
    width: 90px;
    height: 20px;
    padding: 8px 4px;
    border-radius: 12px;
    font-size: 18px;
  }
`;
