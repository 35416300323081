import React from 'react';
import {
  OverallBox,
  PopUpHeader,
  PopUpHeaderText,
  PopUpButtonsOrg,
} from './styles.js';
import xButton from 'images/xButton.svg';
import PopUpButton from 'components/PopUpButton/index.js';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { goals } from 'utils/constants';
function PopUpBox({ changePopUp, campaign }) {
  return (
    <OverallBox>
      <PopUpHeader>
        <PopUpHeaderText>Select your goal</PopUpHeaderText>
        <Link to="/issuer">
          <img src={xButton} />
        </Link>
      </PopUpHeader>
      <PopUpButtonsOrg>
        {goals.map(({ name, value, icon, description }) => (
          <div key={value}>
            <PopUpButton
              changePopUp={() =>
                changePopUp({
                  step: 1,
                  data: { ...campaign.data, goal_id: value },
                })
              }
              logo={icon}
              goal={name}
              description={description}
              className={value == campaign.data.goal_id ? 'active' : ''}
            />
          </div>
        ))}
      </PopUpButtonsOrg>
    </OverallBox>
  );
}

PopUpBox.propTypes = {
  changePopUp: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
};

export default PopUpBox;
