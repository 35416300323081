import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';

const StyledError = styled.div`
  color: red;
  font-family: ${({ theme: { fonts } }) => fonts.secondary};
  font-size: 12px;
`;

const DatePicker = ({ error, ...rest }) => (
  <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        {...rest}
        renderInput={(params) => (
          <TextField
            sx={{
              width: 205,
              borderRadius: 1,
              backgroundColor: 'white',
            }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
    {error ? <StyledError>{error}</StyledError> : null}
  </>
);
DatePicker.propTypes = {
  error: PropTypes.string,
};
export default DatePicker;
