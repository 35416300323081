import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import StatusSwitch from 'components/StatusSwitch';
import { campaignStatus } from 'utils/constants';
import { OverallBox, ActiveIconFormat, ViewInsightsButton } from './styles';

function CampaignSummaryTable({ campaigns, updateStatus, campaignNavigate }) {
  const today = moment(new Date());
  const getDaysLeft = (endDate) => {
    endDate = moment(endDate);
    return endDate.diff(today, 'days');
  };

  return (
    <OverallBox>
      <Paper
        sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}
      >
        <TableContainer sx={{ maxHeight: 320 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            classes={{ root: 'campaign-table', stickyHeader: 'sticky-header' }}
          >
            <TableHead>
              <TableRow classes={{ root: 'campaign-header-row' }}>
                <TableCell align="left" classes={{ root: 'campaign-cell' }}>
                  Status
                </TableCell>
                <TableCell align="left" classes={{ root: 'campaign-cell' }}>
                  Campaign Name
                </TableCell>
                <TableCell align="center" classes={{ root: 'campaign-cell' }}>
                  Goal Progress
                </TableCell>
                <TableCell align="center" classes={{ root: 'campaign-cell' }}>
                  Days Left
                </TableCell>
                <TableCell align="center" classes={{ root: 'campaign-cell' }}>
                  Cost
                </TableCell>
                <TableCell align="center" classes={{ root: 'campaign-cell' }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.map((campaign) => {
                const {
                  id,
                  name,
                  status,
                  end_date,
                  increase_by_customers,
                  customers_rewarded,
                  cost,
                } = campaign;
                const daysLeft = getDaysLeft(end_date);
                const statusText = campaignStatus.find(
                  ({ value }) => value == status,
                ).text;

                const progress = increase_by_customers
                  ? (customers_rewarded * 100) / increase_by_customers
                  : 0;
                return (
                  <TableRow
                    key={name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    classes={{ root: `campaign-row campaign-${statusText}` }}
                  >
                    <TableCell
                      align="left"
                      classes={{ root: 'campaign-cell capitalize' }}
                    >
                      <ActiveIconFormat>
                        {statusText === 'ended' || statusText === 'pending' ? (
                          statusText
                        ) : (
                          <StatusSwitch
                            status={statusText == 'active'}
                            updateStatus={() => {
                              updateStatus(campaign);
                            }}
                          />
                        )}
                      </ActiveIconFormat>
                    </TableCell>
                    <TableCell align="left" classes={{ root: 'campaign-cell' }}>
                      {name}
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: 'campaign-cell' }}
                    >
                      {progress.toFixed()}% Complete
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: 'campaign-cell' }}
                    >
                      {daysLeft > 0 ? daysLeft : 0} days left
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: 'campaign-cell' }}
                    >
                      £{cost || 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: 'campaign-cell' }}
                    >
                      <ViewInsightsButton
                        onClick={() =>
                          campaignNavigate(`/issuer/campaign/edit/${id}`)
                        }
                        disabled={statusText === 'ended'}
                      >
                        Edit
                      </ViewInsightsButton>
                      <ViewInsightsButton
                        onClick={() =>
                          campaignNavigate(`/issuer/campaign/${id}`)
                        }
                      >
                        View
                      </ViewInsightsButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </OverallBox>
  );
}

CampaignSummaryTable.propTypes = {
  campaigns: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  campaignNavigate: PropTypes.func.isRequired,
};

export default CampaignSummaryTable;
