import React, { useState } from 'react';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  LoginContainer,
  CenterBox,
  StyledInputBox,
  ButtonBox,
  StyledHeading,
} from './styles.js';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { forgotPassword } from 'apiServices/auth';
import { handleApiErrors } from 'utils/helper.js';

const initialData = {
  email: '',
};
const ForgotPassword = () => {
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const delayRedirect = () => {
    setTimeout(() => navigate('/'), 1500);
  };

  const handleSubmit = (values, { setErrors }) => {
    forgotPassword(values)
      .then(({ message }) => {
        setSuccess(message);
        delayRedirect();
      })
      .catch(({ errors }) => {
        setErrors(handleApiErrors(errors));
      });
  };
  return (
    <Formik
      initialValues={initialData}
      validationSchema={Yup.object({
        email: Yup.string()
          .required('This field is required.')
          .email('Please enter a valid email address.'),
      })}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <LoginContainer>
            <CenterBox>
              <StyledHeading>
                Please enter the email address associated with your Lyfe account
              </StyledHeading>
              {success && <Alert severity="success">{success}</Alert>}
              <StyledInputBox>
                <InputField
                  id="email"
                  name="email"
                  placeholder="janedoe@gmail.com"
                />
              </StyledInputBox>
              <ButtonBox>
                <Button
                  data-testid="submit"
                  buttonType="primary"
                  variant="contained"
                  type="submit"
                >
                  Send Request
                </Button>
                <Link to="/">Back to sign in</Link>
              </ButtonBox>
            </CenterBox>
          </LoginContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
