import styled from 'styled-components';

export const OverallBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.primary};
  background: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  border-radius: 14px;
  align-items: center;
  margin: 0 auto;
`;
export const CampaignBox = styled.div`
  width: 980px;
  border-radius: 14px;
  padding: 32px;
  margin: 20px auto;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

export const PopUpHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3%;
  width: 90%;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 42.5%;
  & ::hover {
    pointer: cursor;
  }
`;

export const PopUpHeaderText = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.black};
`;

export const BlueHeader = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.darkLyfe};
  width: 8%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 18px;
  justify-content: center;
  height: 25px;
  align-items: center;
  color: white;
  margin-right: 84%;
  margin-top: 4%;
  margin-bottom: 8px;
}
`;

export const DetailsOrg = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 92.5%;
  height: 80px;
  align-items: center;
  justify-content: space-around;
  padding: 12px;
`;

export const TypeCampaignNameOutline = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  height: 72px;
  width: 430px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  & .campaign-name-input input {
    width: 380px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.lightGray};
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
  }
  & ::placeholder {
    color: ${({ theme }) => theme.colors.black};
    font-style: italic;
    font-size: 16px;
  }
  & input:focus::placeholder {
    color: transparent;
  }
`;

export const DateOutline = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  height: 66px;
  width: 205px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

export const GoalOrg = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 92.5%;
  height: 90px;
  align-items: center;
  padding: 12px;
  & .increase-by {
    & input {
      border-radius: 12px;
      background-color: ${({ theme }) => theme.colors.lightGray};
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.black};
      width: 142px;
    }
  }
  & ::placeholder {
    display: flex;
    color: ${({ theme }) => theme.colors.black};
    font-style: italic;
    white-space: initial;
    align-items: center;
    justify-content: center;
  }
  & input:focus::placeholder {
    color: transparent;
  }
`;

export const IncreaseByText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18 px;
  color: ${({ theme }) => theme.colors.black};
  width: 81px;
`;

export const CustomersNumber = styled.div`
  background: ${({ theme }) => theme.colors.lightGray};
  border: 0.5px solid;
  border-radius: 12px;
  width: 110px;
  height: 45px;
`;

export const CustomersEngagedText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  width: 120px;
  margin-left: 2%;
`;

export const CustomersEngagedDropdownOrg = styled.div`
  display: flex;
  width: 490px;
  align-items: center;
  justify-content: space-between;
`;

export const CompleteCampaignButton = styled.div`
  & .submit-camapign-button {
    width: 244px;
    height: 42px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.realLyfe};
    margin-top: 32px;
    margin-bottom: 40px;
    border-radius: 9px;
    justify-content: center;
    display: flex;
    align-items: center;
    :hover {
      background-color: ${({ theme }) => theme.colors.darkLyfe};
      cursor: pointer;
    }
  }
`;
