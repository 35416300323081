import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const Logo = styled.div`
  margin-left: '40px';
  display: flex;
  align-items: center;
`;

export const CompanyName = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
  .logout-pane {
    position: absolute;
    left: -29px;
    top: 30px;
    background: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
    padding: 5px 12px;
  }
  .logout-pane.visible {
    display: flex;
  }
  .logout-pane.hidden {
    display: none;
  }
  .transparent-button {
    width: 100px;
  }
`;
export const Question = styled.div`
  width: 25px;
  height: 25px;
`;
