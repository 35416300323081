import styled from 'styled-components';

export const StyledDiv = styled.div`
  label {
    font-weight: 600;
  }
  & .text-input {
    width: 100%;
    margin: 10px 0;
  }
`;

export const StyledError = styled.div`
  color: red;
  font-family: ${({ theme: { fonts } }) => fonts.secondary};
  font-size: 14px;
`;
