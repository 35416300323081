import React from 'react';
import PropTypes from 'prop-types';
import { ButtonOrg, ImageFormat, GoalText, DescriptionText } from './styles.js';

const PopUpButton = ({ changePopUp, logo, goal, description, className }) => {
  return (
    <ButtonOrg onClick={changePopUp} className={className}>
      <ImageFormat>
        <img src={logo} />
      </ImageFormat>
      <GoalText>{goal}</GoalText>
      <DescriptionText>{description}</DescriptionText>
    </ButtonOrg>
  );
};

PopUpButton.propTypes = {
  logo: PropTypes.any.isRequired,
  goal: PropTypes.string.isRequired,
  changePopUp: PropTypes.any.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default PopUpButton;
