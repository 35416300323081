import styled from 'styled-components';
export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  background-color: ${({ theme: { colors } }) => colors.gray};
  height: 100vh;
`;
export const CenterBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 380px;
  min-height: 300px;
  border-radius: 12px;
  alignitems: center;
  padding: 40px;
  margin-top: -5%;
`;
export const StyledInputBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const StyledHeading = styled.h2`
  font-size: 20px;
  margin: 20px 0;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
`;
export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & a {
    margin-top: 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.defaultColors.primary};
  }
`;
