import React from 'react';
import PropTypes from 'prop-types';
import {
  OverallBox,
  TitleFormat,
  ValueFormat,
  SeeMoreButton,
} from './styles.js';
function CampaignStatus({ title, value, handleClick }) {
  return (
    <OverallBox>
      <TitleFormat>{title}</TitleFormat>
      <ValueFormat>{value}</ValueFormat>
      <SeeMoreButton onClick={handleClick}>See More</SeeMoreButton>
    </OverallBox>
  );
}
CampaignStatus.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default CampaignStatus;
