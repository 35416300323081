import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultColors.backgroudColor};
  min-height: 800px;
  font-family: ${({ theme }) => theme.fonts.primary};
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 30px;
`;
const NotFound = () => {
  return (
    <StyledContainer>
      <div className="not-found">
        404! <br /> Page not found!
        <br />
      </div>
      <Link to="/">Back to Lyfe</Link>
    </StyledContainer>
  );
};

export default NotFound;
