import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from '@mui/material/Button';
import styled, { css } from 'styled-components';

const Input = styled.DefaultButton;
const StyledButton = styled.div`
  & .MuiButton-root {
    width: ${(props) => (props.width ? props.width : '150px')};
    border-radius: 8px;
    ${Input}:hover {
      opacity: 0.7;
    }
    ${({ buttonType, theme: { colors, fonts } }) => {
      switch (buttonType) {
        case 'primary':
          return css`
            background-color: ${colors.defaultColors.primary};
            color: ${colors.white};
            ${Input}:hover {
              background-color: ${colors.defaultColors.primary};
            }
            ${Input}:hover {
              background-color: ${colors.darkGray};
            }
          `;
        case 'secondary':
          return css`
            background-color: ${colors.midDarkGray};
            color: ${colors.black};
            ${Input}:hover {
              background-color: ${colors.gray};
            }
          `;
        case 'transparent':
          return css`
            background-color: ${colors.transparent};
            color: ${colors.defaultColors.primary};
            ${Input}:hover {
              background-color: ${colors.transparent};
            }
          `;
        case 'congrats':
          return css`
          width: 139px;
          height: 42px;
          background-color: ${colors.realLyfe};
          border-radius: 9px;
          font-family: ${fonts.primary};
          text-transform: none;
          margin-left: 20px;
          color: ${colors.white};
          {Input}:hover {
            background-color: ${colors.lightLyfe};
          }
        `;

        default:
          return css`
            background-color: ${colors.defaultColors.primary};
            color:primary ${Input}:hover {
              background-color: ${colors.defaultColors.primary};
            }
          `;
      }
    }}
  }
`;
const Button = ({ buttonType, ...rest }) => {
  return (
    <StyledButton buttonType={buttonType}>
      <DefaultButton {...rest} />
    </StyledButton>
  );
};

Button.propTypes = {
  buttonType: PropTypes.string,
};
Button.defaultProps = {
  buttonType: 'primary',
};

export default Button;
