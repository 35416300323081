import React from 'react';
import { StyledContainer } from './styles.js';
import { powerBiUrl } from 'utils/constants';
const Insights = () => {
  return (
    <StyledContainer>
      <iframe
        src={powerBiUrl}
        title="Power BI"
        width="100%"
        height="100%"
      ></iframe>
    </StyledContainer>
  );
};
export default Insights;
