import React from 'react';
import propTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import {
  campaignRules,
  rulesBasedFields,
  daysActiveOptions,
} from 'utils/constants';
import {
  RulesOrg,
  InsertRuleOrg,
  InsertRuleDesc,
  RuleCategoriesOrg,
  StyledTime,
} from './styles.js';
const pendingCampaign = 1;
const CampaignRules = ({
  campaign,
  handleChange,
  updateCampaign,
  handleNumericChange,
  errors,
  setError,
  removeErrors,
  disabled = false,
}) => {
  const updateDateActive = (event) => {
    const {
      target: { value },
    } = event;
    updateCampaign({
      ...campaign,
      days_active: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleRuleUpdate = ({ target: { name, value } }) => {
    if (!disabled || (disabled && campaign.status === pendingCampaign)) {
      updateCampaign({
        ...campaign,
        spent_amount: '',
        days_active: [],
        number_of_times: '',
        start_time: '',
        end_time: '',
        [name]: value,
      });
    }
  };
  const {
    rule_id,
    spent_amount,
    days_active,
    number_of_times,
    start_time,
    end_time,
    points_rewarded,
  } = campaign;
  return (
    <RulesOrg>
      <InsertRuleOrg>
        <InsertRuleDesc>Determine customer eligibility:</InsertRuleDesc>

        <Box sx={{ minWidth: 675 }}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                fontFamily: 'Josefin Sans',
                fontSize: '18px',
                color: '#373737',
                fontWeight: '500',
              }}
            >
              Insert Rule
            </InputLabel>
            <div>
              <CustomSelect
                label="Insert Rule"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="rule_id"
                value={rule_id}
                onChange={handleRuleUpdate}
                menuItems={campaignRules}
                error={errors.rule_id}
                onBlur={setError}
                onFocus={removeErrors}
                disabled={disabled}
              />
            </div>
          </FormControl>
        </Box>
      </InsertRuleOrg>
      <RuleCategoriesOrg>
        {rulesBasedFields.spent_amount.includes(rule_id) && (
          <CustomInput
            type="number"
            inputBoxClass="whiteBox"
            placeholder="Spend Amount"
            name="spent_amount"
            value={spent_amount}
            onChange={handleNumericChange}
            error={errors.spent_amount}
            onBlur={setError}
            onFocus={removeErrors}
            disabled={disabled}
          />
        )}
        {rulesBasedFields.number_of_times.includes(rule_id) && (
          <CustomInput
            type="number"
            inputBoxClass="whiteBox"
            placeholder="Number of times"
            name="number_of_times"
            value={number_of_times}
            onChange={handleNumericChange}
            error={errors.number_of_times}
            onBlur={setError}
            onFocus={removeErrors}
            disabled={disabled}
          />
        )}
        {rulesBasedFields.days_active.includes(rule_id) && (
          <div className="days-active-select">
            <FormControl>
              <InputLabel
                id="days-active-select-label"
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '18px',
                  color: '#373737',
                  fontWeight: '500',
                }}
              >
                Days Active
              </InputLabel>

              <CustomSelect
                labelId="days-active-select-label"
                id="days-active-select"
                name="days_active"
                label="Days Active"
                inputBoxClass="selectBox"
                value={days_active}
                onChange={updateDateActive}
                menuItems={daysActiveOptions.map((day) => ({
                  text: day,
                  value: day,
                }))}
                multiple
                error={errors.days_active}
                onBlur={setError}
                onFocus={removeErrors}
                disabled={disabled}
                displayEmpty
              />
            </FormControl>
          </div>
        )}
        {rulesBasedFields.hours_active.includes(rule_id) && (
          <StyledTime>
            <div className="time">
              <label>Start Time</label>
              <CustomInput
                type="time"
                name="start_time"
                className="time"
                value={start_time}
                onChange={handleChange}
                placeholder=""
                error={errors.start_time}
                onBlur={setError}
                onFocus={removeErrors}
                disabled={disabled}
              />
            </div>
            <div className="time">
              <label>End Time</label>
              <CustomInput
                type="time"
                name="end_time"
                value={end_time}
                onChange={handleChange}
                error={errors.end_time}
                onBlur={setError}
                onFocus={removeErrors}
                disabled={disabled}
              />
            </div>
          </StyledTime>
        )}
        <CustomInput
          type="number"
          inputBoxClass="whiteBox"
          placeholder="No. Points Rewarded"
          name="points_rewarded"
          value={points_rewarded}
          onChange={handleNumericChange}
          error={errors.points_rewarded}
          onBlur={setError}
          onFocus={removeErrors}
          disabled={disabled}
        />
      </RuleCategoriesOrg>
    </RulesOrg>
  );
};

CampaignRules.propTypes = {
  campaign: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  updateCampaign: propTypes.func.isRequired,
  handleNumericChange: propTypes.func.isRequired,
  errors: propTypes.object.isRequired,
  setError: propTypes.func.isRequired,
  removeErrors: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

export default CampaignRules;
