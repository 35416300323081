import styled from 'styled-components';

export const Popup = styled.div`
  width: 544px;
  min-height: 278px;
  background: ${({ theme }) => theme.colors.lightGray2};
  font-family: ${({ theme }) => theme.fonts.primary};
`;
export const StyledButton = styled.div`
  & .yesNoButton {
    width: 150px;
    margin: 10px;
  }
`;
export const PopupHeader = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`;

export const PopupText = styled.div`
  position: relative;
  margin: 40px 12px 32px;
  text-align: center;
  font-size: 22px;
  line-height: 26px;
`;
export const PopupSubText = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 5px 22px 12px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
`;

export const ButtonHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40%;
  margin: 20px auto 25px;
`;
export const StyledError = styled.div`
  color: red;
  font-family: ${({ theme: { fonts } }) => fonts.secondary};
  font-size: 13px;
  text-align: center;
`;
