export const serverErrors = ({
  response,
  request,
  message,
  config,
  response: { data, data: error = {}, status } = {},
} = {}) => {
  switch (true) {
    case response && data && status >= 400 && status <= 499 && status !== 404:
      return returnErrorObject(true, error.errors, error.message, false);

    case response && status === 401:
      return returnErrorObject(true, error, 'Unauthorized', false);

    case response && status === 404:
      return returnErrorObject(true, error, 'Not found', false);

    case response && status >= 500:
      return returnErrorObject(false, null, 'Internal Server Error', true);

    case !response && request:
      return returnErrorObject(false, null, request, false);

    case !!message:
      return returnErrorObject(false, null, message, false);

    default:
      return returnErrorObject(false, null, config, false);
  }
};

const returnErrorObject = (clientError, errors, message, serverError) => ({
  clientError,
  errors,
  message,
  serverError,
});
