import PoundCoin from 'images/PoundCoin.svg';
import DollarDeposit from 'images/DollarDeposit.svg';
import Award from 'images/Award.svg';
import Calendar from 'images/Calendar.svg';
import ShoppingBag from 'images/ShoppingBag.svg';
import Card from 'images/Card.svg';
export const powerBiUrl =
  'https://app.powerbi.com/view?r=eyJrIjoiYzQ3Mzc5YzUtNTUyMy00NjYwLWE1MDMtNTdiNGRjMTAxZWFmIiwidCI6ImVkNWQ1ZTVkLWU5OTktNDZlMy05NjAwLWYxYWM1OWU1NDg3OCJ9';
export const goals = [
  {
    name: 'Increase Revenue',
    value: 1,
    icon: PoundCoin,
  },
  {
    name: 'Increase ATV',
    value: 2,
    icon: DollarDeposit,
    description: 'Average Transaction Value',
  },
  {
    name: 'Reward Loyalty',
    value: 3,
    icon: Award,
  },
  {
    name: 'Encourage Bookings',
    value: 4,
    icon: Calendar,
    description: 'Specific to date and time',
  },
  {
    name: 'Incentivise Repeat Purchases',
    value: 5,
    icon: ShoppingBag,
  },
  { name: 'Boost Cards Linked', value: 6, icon: Card },
];

export const campaignRules = [
  {
    text: 'Spend over £_ on a specific day',
    value: 1,
  },
  {
    text: 'Spend over £_ in a transaction',
    value: 2,
  },
  {
    text: 'Spend _ times in a time period',
    value: 3,
  },
  {
    text: 'Spend over £_ in a time period',
    value: 4,
  },
  {
    text: 'Spend over _ times in total',
    value: 5,
  },
  {
    text: ' ~Coming Soon~ Be a top _ spender within a time period',
    value: 6,
    disabled: true,
  },
  {
    text: '~Coming Soon~ Link a new card',
    value: 7,
    disabled: true,
  },
  {
    text: '~Coming Soon~ Sign up to our email newsletter',
    value: 8,
    disabled: true,
  },
];

export const customersOptions = [
  {
    text: 'All Customers',
    value: 1,
  },
  {
    text: 'New Customers',
    value: 2,
  },
  {
    text: 'Repeat Customers',
    value: 3,
  },
];
export const rulesBasedFields = {
  spent_amount: [1, 2, 4],
  days_active: [1, 3, 4],
  number_of_times: [3, 5],
  hours_active: [3, 4],
};

export const daysActiveOptions = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const campaignStatus = [
  {
    text: 'pending',
    value: 1,
  },
  {
    text: 'active',
    value: 2,
  },
  {
    text: 'paused',
    value: 3,
  },
  {
    text: 'ended',
    value: 4,
  },
];
export const statusConst = { pending: 1, active: 2, paused: 3, ended: 4 };

export const campaignStatusFilter = [
  {
    text: 'All Campaigns',
    value: 0,
  },
  {
    text: 'Pending Campaigns',
    value: 1,
  },
  {
    text: 'Active Campaigns',
    value: 2,
  },
  {
    text: 'Paused Campaigns',
    value: 3,
  },
  {
    text: 'Past Campaigns',
    value: 4,
  },
];
