import styled from 'styled-components';

export const StyledDiv = styled.div`
  label {
    font-weight: 600;
  }
  & .customSelect {
    width: 100%;
    font-family: ${({ theme: { fonts } }) => fonts.primary};
  }
`;

export const StyledError = styled.div`
  color: red;
  font-family: ${({ theme: { fonts } }) => fonts.secondary};
  font-size: 12px;
`;
