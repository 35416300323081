import styled from 'styled-components';

export const OverallBox = styled.div`
  display: flex;
  border-radius: 12px;
  align-items: center;
  margin: 30px 0;
  width: 100%;
  font-weight: 500;
  padding: 15px 24px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.midDarkGray};
  & .campaign-table {
    background-color: ${({ theme }) => theme.colors.midDarkGray};
    border-radius: 12px;
  }
  & .sticky-header {

  }
  & .campaign-header-row  {
    background-color: ${({ theme }) => theme.colors.white};
    & .campaign-cell{
      font-weight: 600;
    }
  }
  & .campaign-row {
    background-color: ${({ theme }) => theme.colors.midDarkGray};
  }
  & .capitalize {
    text-transform: capitalize;
  }
  & .campaign-cell {
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 7px solid ${({ theme }) => theme.colors.midDarkGray};
    font-family: ${({ theme }) => theme.fonts.primary};
  }
  & td:first-child,
  th:first-child {
    border-radius: 12px 0 0 12px;
  }
  & td:last-child,
  th:last-child {
    border-radius: 0 12px 12px 0;
  }
  }
  & .campaign-active {
    background-color: ${({ theme }) => theme.colors.white};
  }
  & .campaign-paused {
    background: ${({ theme }) => theme.colors.offWhite};
  }
  & .campaign-ended {
    background: ${({ theme }) => theme.colors.gray2};
  }
   & .campaign-pending {
    background-color: ${({ theme }) => theme.colors.midDarkGray};
  }
`;

export const StatusIconSection = styled.div`
  margin-left: 4%;
  width: 10.21%;
`;

export const ActiveIconFormat = styled.div`
  margin-top: 4.5%;
  margin-left: 4%;
`;

export const ViewInsightsButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  text-align: center;
  border-radius: 9px;
  border-width: 1px;
  background-color: ${({ theme }) => theme.colors.midGray};
  margin: 1px 3px;
  padding: 6px 12px;
  :hover {
    background-color: ${({ theme }) => theme.colors.midDarkGray};
    cursor: pointer;
  }
`;
